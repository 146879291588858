import { makeAutoObservable, action, toJS } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import userServices, { IGetNotificationsListResponse } from '@/services/userServices';
import {
  CheckExistType,
  SignInType,
  SignUpType,
  SignOffType,
  SendEmailType,
  ResetPasswordType,
  EnterpriseType,
  UserType,
  ResetEmailType,
  ResetPhoneType,
  BindBindGoogleOauthType,
  GetVerifyCodeType,
  UserCourierType,
  NotificationsStatus,
  NotificationType,
  OrderListType,
  EnterpriseOrderType,
  CurrencyType,
} from './types';

export default class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'UserStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async checkExist(params: CheckExistType) {
    const res = await userServices.checkExist(params);
    return Promise.resolve(res);
  }

  async signIn(params: SignInType) {
    const res = await userServices.signIn(params);
    return Promise.resolve(res);
  }

  async googleAuthState() {
    const res = await userServices.googleAuthState();
    return Promise.resolve(res.state);
  }

  async sendEmailLink(params: SendEmailType) {
    const res = await userServices.sendEmailLink(params);
    return Promise.resolve(res);
  }

  async signUp(params: SignUpType) {
    const res = await userServices.signUp(params);
    return Promise.resolve(res);
  }

  async resetPassword(params: ResetPasswordType) {
    const res = await userServices.resetPassword(params);
    return Promise.resolve(res);
  }

  async signOff(params: SignOffType) {
    const res = await userServices.signOff(params);
    return Promise.resolve(res);
  }

  // 获取user info
  async getUser() {
    const res = await userServices.getUser();
    this.saveUser(res.user, res.enterprise);
    return Promise.resolve(res);
  }

  // 获取验证码
  async getVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.getVerifyCode(params);
    return Promise.resolve(res);
  }

  // 验证手机号/邮箱
  async checkVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.checkVerifyCode(params);
    return Promise.resolve(res);
  }

  // 重置邮箱
  async resetEmail(params: ResetEmailType) {
    const res = await userServices.resetEmail(params);
    return Promise.resolve(res);
  }

  // 重置手机号
  async resetPhone(params: ResetPhoneType) {
    const res = await userServices.resetPhone(params);
    return Promise.resolve(res);
  }

  // 绑定谷歌账号
  async bindGoogle(params: BindBindGoogleOauthType) {
    const res = await userServices.bindGoogle(params);
    return Promise.resolve(res);
  }

  // 获取登录信息
  async getSignInInfo() {
    const res = await userServices.getSignInInfo();
    return Promise.resolve(res);
  }

  // 移除登录信息
  async removeSignInInfo(id: number) {
    const res = await userServices.removeSignInInfo(id);
    return Promise.resolve(res);
  }

  // 获取Courier设置 (邮件语言及通知状态)
  async getCourier() {
    const res = await userServices.getCourier();
    return Promise.resolve(res);
  }

  // 更新Courier设置 (邮件语言及通知状态)
  async updateCourier(params: UserCourierType) {
    const res = await userServices.updateCourier(params);
    return Promise.resolve(res);
  }

  avatar: string = '';
  countryCode: string = '';
  email: string = '';
  gender: number = 0;
  gid: string = '';
  nickname: string = '';
  password: string = '';
  phone: string = '';
  uid: number = 0;

  createdAt: string = '';
  eid: number = 0;
  id: number = 0;
  invitationCode: string = '';
  licenseNo: string = '';
  name: string = '';
  operator: string = '';
  operationStatus: string = '';
  registeredTime: string = '';
  saveUser(user: UserType, enterprise: EnterpriseType) {
    this.avatar = user.avatar || '';
    this.countryCode = user.countryCode || '';
    this.email = user.email || '';
    this.gender = user.gender || 0;
    this.gid = user.gid || '';
    this.nickname = user.nickname || '';
    this.password = user.password || '';
    this.phone = user.phone || '';
    this.uid = user.uid || 0;

    this.createdAt = enterprise.createdAt || '';
    this.eid = enterprise.eid || 0;
    this.id = enterprise.id || 0;
    this.invitationCode = enterprise.invitationCode || '';
    this.licenseNo = enterprise.licenseNo || '';
    this.name = enterprise.name || '';
    this.operator = enterprise.operator || '';
    this.operationStatus = enterprise.operationStatus || '';
    this.registeredTime = enterprise.registeredTime || '';

    // this.updateChannelTalkUserInfo(user);
  }

  clearUser() {
    this.avatar = '';
    this.countryCode = '';
    this.email = '';
    this.gender = 0;
    this.gid = '';
    this.nickname = '';
    this.password = '';
    this.phone = '';
    this.uid = 0;

    this.createdAt = '';
    this.eid = 0;
    this.id = 0;
    this.invitationCode = '';
    this.licenseNo = '';
    this.name = '';
    this.operator = '';
    this.operationStatus = '';
    this.registeredTime = '';
  }

  clearStore() {
    this.clearUser();
  }

  unreadCount: number = 0; // 未读消息数
  unreadMessageList: IGetNotificationsListResponse = { count: 0, list: [] }; // 未读消息列表
  undeletedMessageList: IGetNotificationsListResponse = { count: 0, list: [] }; // 全部消息列表
  unreadEphemeralIds: number[] = []; // 临时未读消息id列表

  saveUnreadList(list: NotificationType[]) {
    this.unreadMessageList.list = list;
  }

  saveUnreadCount(value: number) {
    this.unreadCount = value;
  }

  saveUnreadCountIds(ids: number[]) {
    this.unreadEphemeralIds.push(...ids);
  }

  saveMessageList(res: IGetNotificationsListResponse, status: NotificationsStatus) {
    if (status === 'UNREAD') {
      // 未读消息列表初始添加status: "UNREAD"标记
      const list = toJS(res.list)?.map((item) => {
        let status = { status: 'UNREAD' };
        Object.assign(item, status);
        return item;
      });
      this.unreadCount = res.count;
      this.unreadMessageList.count = res.count;
      this.unreadMessageList.list = list;
    } else {
      this.undeletedMessageList.count = res.count;
      this.undeletedMessageList.list = res.list;
    }
  }

  // 收到新的开通平台消息
  isNewPlatformMessage = false;
  setIsNewPlatformMessage(value: boolean) {
    this.isNewPlatformMessage = value;
  }

  clearNotifications() {
    this.unreadCount = 0;
    this.unreadMessageList.count = 0;
    this.unreadMessageList.list = [];
    this.undeletedMessageList.count = 0;
    this.undeletedMessageList.list = [];
    this.isNewPlatformMessage = false;
  }

  // 获取通知列表
  async getNotificationsList(status: NotificationsStatus, limit: number = 100, offset: number = 0) {
    const params = {
      limit,
      offset,
      status,
    };
    const res = await userServices.getNotificationsList(params);
    this.saveMessageList(res, status);
    this.unreadEphemeralIds = [];
    return Promise.resolve(res);
  }

  // 更新消息状态(已读，删除等)
  async updateNotifications(ids: number[], status: NotificationsStatus) {
    const params = {
      ids,
      status,
    };
    const res = await userServices.updateNotifications(params);
    return Promise.resolve(res);
  }

  orderList: OrderListType[] = [];
  setOrderList(list: OrderListType[]) {
    this.orderList = list;
  }

  // 企业订阅收费列表
  async getOrderList() {
    const res = await userServices.getOrderList();
    const sortList = res.list.sort((a, b) => a.minMembers - b.minMembers);
    this.setOrderList(sortList);
    return Promise.resolve(res);
  }

  // 根据货币筛选订阅列表
  getCurrencyOrderList(currency: CurrencyType = 'USD') {
    const currencyList = toJS(this.orderList).filter((item) => item.currency === currency);
    return currencyList;
  }

  enterpriseOrderList: EnterpriseOrderType[] = [];
  setEnterpriseOrderList(list: EnterpriseOrderType[]) {
    this.enterpriseOrderList = list;
  }

  // 当前企业账号的订阅列表
  async getEnterpriseOrderList() {
    const params = {
      limit: 999,
    };
    const res = await userServices.getEnterpriseOrderList(params);
    this.setEnterpriseOrderList(res.list);
    return Promise.resolve(res);
  }
}
