import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import walletServices, {
  IWalletBalanceResponse,
  IDuringEarningParams,
  IMemberDuringEarningParams,
  IAssignEarningParams,
  IWalletUpdateAccountParams,
} from '@/services/walletServices';
import { TransactionListParamsType, TransactionListType } from './types';
import { BankListType } from './types';

export default class WalletStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'WalletStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async getTransactionList(page: number, size: number, positive: boolean) {
    const params: TransactionListParamsType = {
      page,
      size,
      positive,
    };
    const res = await walletServices.getTransactionList(params);
    this.saveTransactionList(res.list);
    return Promise.resolve(res);
  }

  rollInList: TransactionListType[] = [];
  rollOutList: TransactionListType[] = [];
  saveTransactionList(list: TransactionListType[]) {
    this.rollInList = list.filter((item) => item.credit >= 0);
    this.rollOutList = list.filter((item) => item.credit < 0);
  }

  //企业收入概览
  balance = 0;
  withdrawable = 0;
  amount = 0;
  totalWithdrawal = 0;
  async getWalletBalance() {
    const res = await walletServices.getWalletBalance();
    this.saveWalletBalance(res);
    return Promise.resolve(res);
  }

  saveWalletBalance(res: IWalletBalanceResponse) {
    this.balance = Number(res.amount);
    this.totalWithdrawal = Number(res.totalWithdrawal);
    this.amount = Number(res.amount) + Number(res.totalWithdrawal);
    this.withdrawable = Number(res.withdrawable);
  }

  //成员详情收入概览
  memberBalance = 0;
  memberWithdrawable = 0;
  memberAmount = 0;
  memberTotalWithdrawal = 0;
  async getMemberBalance(mid: number) {
    const res = await walletServices.getMemberBalance(mid);
    this.saveMemberBalance(res);
    return Promise.resolve(res);
  }
  saveMemberBalance(res: IWalletBalanceResponse) {
    this.memberBalance = Number(res.amount);
    this.memberTotalWithdrawal = Number(res.totalWithdrawal);
    this.memberAmount = Number(res.amount) + Number(res.totalWithdrawal);
    this.memberWithdrawable = Number(res.withdrawable);
  }

  // 查询区间收益
  async getDuringEarning(params: IDuringEarningParams) {
    const res = await walletServices.getDuringEarning(params);
    return Promise.resolve(res);
  }

  //查询提现详情表
  async getMemberWithdrawalDetail(wid?: number) {
    const res = await walletServices.getMemberWithdrawalDetail(wid);
    return Promise.resolve(res);
  }

  //导出提现详情表
  async exportWithdrawal(wid?: number) {
    const res = await walletServices.exportWithdrawal(wid);
    return Promise.resolve(res);
  }

  // 查询成员区间收益
  async getMemberDuringEarning(params: IMemberDuringEarningParams) {
    const res = await walletServices.getMemberDuringEarning(params);
    return Promise.resolve(res);
  }

  // 查询区间收益
  async getAssignEarning(params: IAssignEarningParams) {
    const res = await walletServices.getAssignEarning(params);
    return Promise.resolve(res);
  }

  // 查询区间收益
  // async getMemberAssignEarning(params: IAssignEarningParams) {
  //   const res = await walletServices.getMemberAssignEarning(params);
  //   return Promise.resolve(res);
  // }

  async getBankList() {
    const res = await walletServices.getBankList();
    this.setBankList(res.banks);
    return Promise.resolve(res);
  }

  bankList: BankListType[] = [];
  setBankList(list: BankListType[]) {
    this.bankList = list;
  }

  async getUserAccount() {
    const res = await walletServices.getUserAccount();
    return Promise.resolve(res);
  }

  async updateUserAccount(params: IWalletUpdateAccountParams) {
    const res = await walletServices.updateUserAccount(params);
    return Promise.resolve(res);
  }

  async sendEmailCode() {
    const res = await walletServices.sendEmailCode();
    return Promise.resolve(res);
  }

  async getTaxes() {
    const res = await walletServices.getTaxes();
    return Promise.resolve(res.list);
  }

  async getWithdrawalList(params: any) {
    const res = await walletServices.getWithdrawalList(params);
    return Promise.resolve(res);
  }

  async applyWithdrawal() {
    const res = await walletServices.applyWithdrawal();
    return Promise.resolve(res);
  }

  async confirmWithdrawal(code: string) {
    const res = await walletServices.confirmWithdrawal(code);
    return Promise.resolve(res);
  }

  async getWithdrawalDetail(id: number) {
    const res = await walletServices.getWithdrawalDetail(id);
    return Promise.resolve(res);
  }

  // 清除Store数据
  clearStore() {
    //企业收益概览
    this.balance = 0;
    this.withdrawable = 0;
    this.amount = 0;
    this.totalWithdrawal = 0;

    //成员收益概览
    this.memberBalance = 0;
    this.memberWithdrawable = 0;
    this.memberAmount = 0;
    this.memberTotalWithdrawal = 0;

    this.rollInList = [];
    this.rollOutList = [];
  }
}
