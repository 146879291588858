import { axios, axios1 } from '../utils/axios';
import {
  TransactionListParamsType,
  TransactionListType,
  EarningUserLevel,
  AssignEarningType,
  BankListType,
  UserAccountType,
  WithdrawalType,
  WithdrawalTaxesType,
  MemberAssignEarningType,
  MemberWithdrawalType,
} from '@/stores/WalletStore/types';

export interface IBalanceResponse {
  balance: number;
}

export interface IFrozenResponse {
  balance: number;
  frozenAdd: number;
  frozenSub: number;
}

export interface IListResponse {
  list: TransactionListType[];
  total: number;
}

export interface IFreeCountResponse {
  total: number;
  used: number;
  free: number;
}

export interface IWalletBalanceResponse {
  amount: string;
  totalWithdrawal: string;
  withdrawable: string;
  tax: string;
  fee: string;
  real: string;
}

export interface IDuringEarningParams {
  unit: 'DAY' | 'MONTH';
  pid?: number;
  start: string;
  end: string;
  cid?: number;
}

export interface IMemberDuringEarningParams {
  unit: 'DAY' | 'MONTH' | 'TU_UNKNOWN';
  date?: string;
  pid?: number;
  limit?: number;
  orders?: string[];
  start?: string;
  end?: string;
  offset?: number;
}

export interface IDuringEarningResponse {
  count: number;
  dates: string[];
  fees: string[];
  levels: EarningUserLevel[];
  rates: string[];
  reals: string[];
  taxes: string[];
  totals: string[];
}

export interface IAssignEarningParams {
  unit: 'DAY' | 'MONTH';
  date?: string;
  cid?: number;
}

export interface IAssignEarningResponse {
  count: number;
  sum: AssignEarningType;
  items: AssignEarningType[];
}

export interface IMemberAssignEarningResponse {
  count: number;
  sum: MemberAssignEarningType;
  items: MemberAssignEarningType[];
}

export interface IWalletBankResponse {
  banks: BankListType[];
}

export interface IWalletUpdateAccountParams {
  code: string;
  recipient: {
    paypal: string;
  };
}

export interface IWalletTaxesResponse {
  list: WithdrawalTaxesType[];
}

export interface IWalletWithdrawalListResponse {
  count: number;
  items: WithdrawalType[];
}
export interface IMemberWalletWithdrawalListResponse {
  count: number;
  items: MemberWithdrawalType[];
}

export interface IWalletExportWithdrawalResponse {
  count: number;
  excel: string;
}

const walletServices = {
  getBalance: () => axios.get<IBalanceResponse, IBalanceResponse>('/coin/v1/balance'),
  getFrozen: () => axios.get<IFrozenResponse, IFrozenResponse>('/coin/v1/balance/frozen'),
  getTransactionList: (params: TransactionListParamsType) =>
    axios.get<IListResponse, IListResponse>('/coin/v1/transaction', { params }),
  //企业收入概览
  getWalletBalance: () =>
    axios.get<IWalletBalanceResponse, IWalletBalanceResponse>('/wallet/v1/balance'),
  //成员详情收入概览
  getMemberBalance: (mid: number) =>
    axios.get<IWalletBalanceResponse, IWalletBalanceResponse>(`/wallet/v1/balance`, {
      params: { mid: mid },
    }),
  getDuringEarning: (params: IDuringEarningParams) =>
    axios.get<IDuringEarningResponse, IDuringEarningResponse>(
      '/spiderman/v1/enterprises/incomes/ts',
      {
        params,
      }
    ),
  getMemberDuringEarning: (params: IMemberDuringEarningParams) =>
    axios.get<IMemberAssignEarningResponse, IMemberAssignEarningResponse>(
      '/spiderman/v1/enterprises/incomes/members',
      {
        params,
      }
    ),
  getAssignEarning: (params: IAssignEarningParams) =>
    axios.get<IAssignEarningResponse, IAssignEarningResponse>('/spiderman/v1/enterprises/incomes', {
      params,
    }),
  // getMemberAssignEarning: (params: IAssignEarningParams) =>
  //   axios.get<IAssignEarningResponse, IAssignEarningResponse>('/spiderman/v1/enterprises/incomes/', {
  //     params,
  //   }),

  getBankList: () => axios.get<IWalletBankResponse, IWalletBankResponse>('/wallet/v1/banks'),
  getUserAccount: () => axios.get<UserAccountType, UserAccountType>('/wallet/v1/recipient'),
  updateUserAccount: (params: IWalletUpdateAccountParams) =>
    axios.put('/wallet/v1/recipient', params),
  sendEmailCode: () => axios.get('/wallet/v1/verify_code'),

  getTaxes: () =>
    axios.get<IWalletTaxesResponse, IWalletTaxesResponse>('/studio/v1/wallet/tax_manage_info'),
  getWithdrawalList: (params: any) =>
    axios.get<IWalletWithdrawalListResponse, IWalletWithdrawalListResponse>(
      '/wallet/v1/withdrawals',
      { params }
    ),
  getMemberWithdrawalDetail: (wid?: number) =>
    axios.get<IMemberWalletWithdrawalListResponse, IMemberWalletWithdrawalListResponse>(
      `/wallet/v1/withdrawals/${wid}/incomes`
    ),
  exportWithdrawal: (wid?: number) =>
    axios1.post('/wallet/v1/withdrawals:export_incomes', { wid: wid }),
  applyWithdrawal: () =>
    axios.post<WithdrawalType, WithdrawalType>('/wallet/v1/withdrawals:prepare', {}),
  confirmWithdrawal: (verificationCode: string) =>
    axios.post('/wallet/v1/withdrawals:confirm', { verificationCode }),
  getWithdrawalDetail: (id: number) =>
    axios.get<WithdrawalType, WithdrawalType>(`/wallet/v1/withdrawals/${id}`),
};

export default walletServices;
