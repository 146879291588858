// console.log('window.location.protocol', window.location.protocol);

const handleBaseUrl = (local?: string) => {
  let baseUrl = '';

  switch (local) {
    case 'enterprise.adobaro.com': // 生产环境
    case 'www.enterprise.adobaro.com': // 生产环境
      baseUrl = `https://api.adobaro.com`;
      break;
    default:
      baseUrl = `https://api.dev.adobaro.com`;
      break;
  }

  return { baseUrl };
};

const CONFIG = {
  BASEURL: handleBaseUrl(window.location.host).baseUrl,
};

export default CONFIG;
