import UserStore from './UserStore';
import CommonStore from './CommonStore';
import LanguageStore from './LanguageStore';
import WalletStore from './WalletStore';
import MemberStore from './MemberStore';

const stores = {
  user: new UserStore(),
  common: new CommonStore(),
  language: new LanguageStore(),
  wallet: new WalletStore(),
  member: new MemberStore(),
};

export default stores;
