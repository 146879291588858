import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// Layout
const DashboardLayout = lazy(() => import('@/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('@/layouts/MainLayout'));
const SignLayout = lazy(() => import('@/layouts/SignLayout'));

// DashboardLayout
const Home = lazy(() => import('@/pages/Home')); // 首页
const MemberManagement = lazy(() => import('@/pages/MemberManagement')); // 成员管理
const MemberDetails = lazy(() => import('@/pages/MemberManagement/MemberDetails')); // 成员详情
const UploadVideo = lazy(() => import('@/pages/MemberManagement/MemberDetails/UploadVideo')); // 成员上传视频
// 成员平台管理
const MemberPlatforms = lazy(
  () => import('@/pages/MemberManagement/MemberDetails/MemberPlatforms')
);
// 成员上传视频
const MemberPlatformDetail = lazy(
  () => import('@/pages/MemberManagement/MemberDetails/MemberPlatformDetail')
); // 成员平台信息
const MemberContents = lazy(() => import('@/pages/MemberManagement/MemberDetails/MemberContents')); // 成员内容管理
const MemberContentDetails = lazy(
  () => import('@/pages/MemberManagement/MemberDetails/MemberContents/MemberContentDetails') // 成员内容详情
);
const MemberEarnings = lazy(() => import('@/pages/MemberManagement/MemberDetails/MemberEarnings')); // 成员收益信息
const Wallet = lazy(() => import('@/pages/Wallet')); // 硬币钱包
const AccountInfo = lazy(() => import('@/pages/Wallet/EarningManagement/AccountInfo')); //账户信息
const MemberPlatformIncomes = lazy(
  () => import('@/pages/Wallet/EarningManagement/MemberIncomes/MemberPlatformIncomes')
);
const Setting = lazy(() => import('@/pages/Setting')); // 设置
const EnterpriseInfo = lazy(() => import('@/pages/Setting/EnterpriseInfo')); // 机构信息
const AccountSecurity = lazy(() => import('@/pages/Setting/AccountSecurity')); // 账号安全
const Notification = lazy(() => import('@/pages/Setting/Notification')); // 通知
const Protocol = lazy(() => import('@/pages/Setting/Protocol')); // 协议
const Cancellation = lazy(() => import('@/pages/Setting/Cancellation')); // 解约管理
const CancellationApply = lazy(() => import('@/pages/Setting/Cancellation/CancellationApply')); // 解约申请
const CancellationRecord = lazy(() => import('@/pages/Setting/Cancellation/CancellationRecord')); // 解约记录

// MainLayout
const NotFound = lazy(() => import('@/pages/NotFound'));
const SignIn = lazy(() => import('@/pages/SignIn'));
const SignUp = lazy(() => import('@/pages/SignUp'));
const Password = lazy(() => import('@/pages/Password'));
const DeviceReminder = lazy(() => import('@/pages/DeviceReminder')); // 请使用PC端浏览器
// 开通平台
const PlatformOperation = lazy(() => import('@/pages/PlatformOperation'));
const SelectPlatform = lazy(() => import('@/pages/PlatformOperation/Platform'));
const GlobalPassSubmit = lazy(() => import('@/pages/PlatformOperation/Platform/GlobalPassSubmit'));
const GlobalPassFailed = lazy(() => import('@/pages/PlatformOperation/Platform/GlobalPassFailed'));
const BindAccount = lazy(() => import('@/pages/PlatformOperation/Platform/BindAccount'));
const RegisterAccount = lazy(() => import('@/pages/PlatformOperation/Platform/RegisterAccount'));
const VerifyAccount = lazy(() => import('@/pages/PlatformOperation/Platform/VerifyAccount'));
const PlatformWaiting = lazy(() => import('@/pages/PlatformOperation/Platform/PlatformWaiting'));
const PlatformIntro = lazy(() => import('@/pages/PlatformOperation/Platform/PlatformIntro'));
const GlobalPass = lazy(() => import('@/pages/PlatformOperation/Platform/GlobalPass'));
const UserAgreementEN = lazy(() => import('@/pages/Protocols/UserAgreement/UserAgreementEN'));
const UserAgreementKO = lazy(() => import('@/pages/Protocols/UserAgreement/UserAgreementKO'));
const PrivacyPolicyEN = lazy(() => import('@/pages/Protocols/PrivacyPolicy/PrivacyPolicyEN'));
const PrivacyPolicyKO = lazy(() => import('@/pages/Protocols/PrivacyPolicy/PrivacyPolicyKO'));
const MarketingConsentEN = lazy(
  () => import('@/pages/Protocols/MarketingConsent/MarketingConsentEN')
);
const MarketingConsentKO = lazy(
  () => import('@/pages/Protocols/MarketingConsent/MarketingConsentKO')
);

const routes = [
  // 重定向
  { path: '/', element: <Navigate to="/home" /> },
  { path: '/setting', element: <Navigate to="/setting/enterprise-info" /> },
  {
    path: '/setting/cancellation',
    element: <Navigate to="/setting/cancellation/apply" />,
  },

  // DashboardLayout
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'home', element: <Home /> }, // 首页
      {
        path: 'member',
        children: [
          { path: '', element: <MemberManagement /> },
          {
            path: 'member-details',
            children: [
              {
                path: '',
                children: [
                  { path: '', element: <MemberDetails /> },
                  { path: 'upload-video', element: <UploadVideo /> },
                  { path: 'platforms', element: <MemberPlatforms /> },
                  { path: 'platform-detail', element: <MemberPlatformDetail /> },
                  {
                    path: 'contents',
                    children: [
                      { path: '', element: <MemberContents /> },
                      { path: 'details', element: <MemberContentDetails /> },
                    ],
                  },
                  { path: 'earnings', element: <MemberEarnings /> },
                ],
              },
            ],
          },
        ],
      }, // 成员管理
      {
        path: 'wallet',
        children: [
          { path: '', element: <Wallet /> },
          { path: 'account-info', element: <AccountInfo /> },
          { path: 'member-platform-incomes', element: <MemberPlatformIncomes /> },
        ],
      }, // 硬币钱包
      // 设置
      {
        path: 'setting',
        element: <Setting />,
        children: [
          { path: 'enterprise-info', element: <EnterpriseInfo /> },
          { path: 'account-security', element: <AccountSecurity /> },
          { path: 'notification', element: <Notification /> },
          { path: 'protocol', element: <Protocol /> },
          {
            path: 'cancellation',
            element: <Cancellation />,
            children: [
              { path: 'apply', element: <CancellationApply /> },
              { path: 'record', element: <CancellationRecord /> },
            ],
          },
        ],
      },
    ],
  },

  // MainLayout
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/home" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'device-reminder', element: <DeviceReminder /> },
      { path: 'user-agreementEN', element: <UserAgreementEN /> },
      { path: 'user-agreementKO', element: <UserAgreementKO /> },
      { path: 'privacy-policyEN', element: <PrivacyPolicyEN /> },
      { path: 'privacy-policyKO', element: <PrivacyPolicyKO /> },
      { path: 'marketing-consentEN', element: <MarketingConsentEN /> },
      { path: 'marketing-consentKO', element: <MarketingConsentKO /> },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [{ path: '', element: <Navigate to="/home" /> }],
  },
  {
    path: '',
    element: <SignLayout />,
    children: [
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'password', element: <Password /> },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'global-pass-submit', element: <GlobalPassSubmit /> },
      { path: 'global-pass-failed', element: <GlobalPassFailed /> },
      {
        path: 'platform-operation',
        children: [
          { path: '', element: <PlatformOperation /> },
          {
            path: 'platform',
            children: [
              { path: '', element: <SelectPlatform /> },
              { path: 'bind', element: <BindAccount /> },
              { path: 'register', element: <RegisterAccount /> },
              { path: 'verify', element: <VerifyAccount /> },
              { path: 'waiting', element: <PlatformWaiting /> },
              { path: 'platform-intro', element: <PlatformIntro /> },
              { path: 'global-pass', element: <GlobalPass /> },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
