import { axios, axios2 } from '../utils/axios';
import {
  CheckExistType,
  SignInType,
  SignUpType,
  SignOffType,
  SendEmailType,
  ResetPasswordType,
  ResetEmailType,
  ResetPhoneType,
  BindBindGoogleOauthType,
  LoginInfoType,
  UserType,
  EnterpriseType,
  GetVerifyCodeType,
  UserCourierType,
  GetNotificationsListParams,
  NotificationType,
  UpdateNotificationsParams,
  OrderListType,
  EnterpriseOrderListParams,
  EnterpriseOrderType,
} from '@/stores/UserStore/types';

export interface ICheckExistResponse {
  isExist: boolean;
}

export interface ISignInResponse {
  token: {
    accessToken: string;
    refreshToken: string;
  };
  guideToOpening: boolean;
}

export interface IGoogleAuthStateResponse {
  state: string;
}

export interface IGetUserResponse {
  enterprise: EnterpriseType;
  user: UserType;
}

export interface ILoginInfoResponse {
  count: number;
  loginHistories: LoginInfoType[];
}

export interface IGetNotificationsListResponse {
  count: number;
  list: NotificationType[];
}

export interface IGetOrderListResponse {
  list: OrderListType[];
}

export interface IGetEnterpriseOrderList {
  list: EnterpriseOrderType[];
}

const userServices = {
  checkExist: (params: CheckExistType) =>
    axios2.get<ICheckExistResponse, ICheckExistResponse>('/user/v2/exist', { params }),
  signIn: (params: SignInType) =>
    axios2.post<ISignInResponse, ISignInResponse>('/user/v2/enterprise/sign_in', params),
  googleAuthState: () =>
    axios2.get<IGoogleAuthStateResponse, IGoogleAuthStateResponse>('/user/v2/state'),
  sendEmailLink: (params: SendEmailType) => axios2.post('/user/v2/email_link', params),
  resetPassword: (params: ResetPasswordType) => axios2.patch('/user/v2/passwd', params),
  signUp: (params: SignUpType) =>
    axios2.post<ISignInResponse, ISignInResponse>('/user/v2/sign_up', params),
  signOff: (params: SignOffType) => axios.post('/user/v2/sign_off', params),
  // 获取验证码
  getVerifyCode: (params: GetVerifyCodeType) => axios.get('/user/v1/verify_code', { params }),
  // 验证手机号/邮箱
  checkVerifyCode: (params: GetVerifyCodeType) => axios.post('/user/v1/verify_code', params),
  // 获取企业基本信息
  getUser: () => axios.get<IGetUserResponse, IGetUserResponse>('/user/v2/enterprise/user'),

  // 重置邮箱
  resetEmail: (params: ResetEmailType) => axios.patch('/user/v2/email', params),
  // 重置手机号
  resetPhone: (params: ResetPhoneType) => axios.patch('/user/v2/phone', params),
  // 绑定google账号
  bindGoogle: (params: BindBindGoogleOauthType) => axios.put('/user/v2/bind_google', params),
  // 获取登录信息
  getSignInInfo: () =>
    axios.get<ILoginInfoResponse, ILoginInfoResponse>('/user/v2/login_histories'),
  // 移除登录信息
  removeSignInInfo: (id: number) => axios.delete(`/user/v2/login_history/${id}`),

  // 获取Courier设置 (邮件语言及通知状态)
  getCourier: () => axios.get<UserCourierType, UserCourierType>('/user/v2/courier'),
  // 更新Courier设置 (邮件语言及通知状态)
  updateCourier: (params: UserCourierType) => axios.patch('/user/v2/courier', params),

  // 获取消息列表
  getNotificationsList: (params: GetNotificationsListParams) =>
    axios.get<IGetNotificationsListResponse, IGetNotificationsListResponse>(
      '/user/v2/courier/receiver_notifications',
      { params }
    ),
  // 更新消息状态(已读、删除) All message status can only be updated as "READ" or "DELETED" by receiver.
  updateNotifications: (params: UpdateNotificationsParams) =>
    axios.patch('/user/v2/courier/receiver_notifications', params),

  // 企业订阅收费列表
  getOrderList: () => axios.get<IGetOrderListResponse, IGetOrderListResponse>('/order/v1/e/fees'),

  // 当前企业账号的订阅列表
  getEnterpriseOrderList: (params: EnterpriseOrderListParams) =>
    axios.get<IGetEnterpriseOrderList, IGetEnterpriseOrderList>('/order/v1/e/subscriptions', {
      params,
    }),
};

export default userServices;
