import { makeAutoObservable, action, toJS } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import memberServices, {
  IGetCreatorParams,
  IGetEnterpriseMembersListParams,
  IGetInvitationRecordsParams,
  ISendMemberInvitationParams,
  ICreateAuthHistoryParams,
} from '@/services/memberServices';
import {
  EnterpriseInfoType,
  EnterpriseTotalDataType,
  InvitationRecordType,
  MemberDataInfoType,
  MemberInviteStatusType,
  MemberMergedListType,
  PublishVideoType,
  ContentListType,
  ContentDataType,
  RepublishVideoType,
  GlobalPassType,
  CreatorPassType,
  OverseaPlatformParams,
  PlatformIncreaseParams,
  PlatformDataType,
  OperatePlatformType,
  OperatePlatformParams,
  PlatformCodeParams,
} from './types';
import { DownloadBatchFiles } from '../CommonStore/types';
import dayjs from 'dayjs';

export default class MemberStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'MemberStore',
      properties: ['currentMemberInfo', 'currentMemberUid', 'currentMemberNickname'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  searchInputRef: any = null;
  setSearchInputRef = (ref: any) => {
    this.searchInputRef = ref;
  };

  // 打开添加成员弹窗
  isAddModalOpen: boolean = false;
  setIsAddModalOpen = (isOpen: boolean) => {
    this.isAddModalOpen = isOpen;
  };

  // 获取成员基础信息列表
  async getCreatorBaseInfoList(uid: number) {
    const params: IGetCreatorParams = {
      limit: 999,
      offset: 0,
      uids: [uid],
    };
    const res = await memberServices?.getCreatorBaseInfoList(params);
    return Promise.resolve(res);
  }

  // 企业的统计数据
  enterpriseTotalData: EnterpriseTotalDataType = {
    platforms: '0',
    fans: '0',
    plays: '0',
    likes: '0',
    works: '0',
    comments: '0',
  };
  saveEnterpriseTotalData(data: EnterpriseTotalDataType) {
    this.enterpriseTotalData = data;
  }
  // 获取企业的统计数据
  async getEnterpriseTotalData() {
    const res = await memberServices?.getEnterpriseTotalData();
    this.saveEnterpriseTotalData(res);
    return Promise.resolve(res);
  }

  // 成员数据信息列表
  membersDataList: MemberDataInfoType[] = [];
  saveMembersDataList(data: MemberDataInfoType[]) {
    this.membersDataList = data;
  }

  // 获取企业成员数据信息列表
  async getMembersDataList() {
    const params: IGetEnterpriseMembersListParams = {
      limit: 999,
      offset: 0,
    };
    const res = await memberServices?.getMembersDataList(params);
    this.saveMembersDataList(res.items);
    return Promise.resolve(res);
  }

  // 获取企业所属成员列表
  async getEnterpriseMembersList() {
    const res = await memberServices?.getEnterpriseMembersList();
    this.saveEnterpriseMembersData(res.count, res.list);
    return Promise.resolve(res);
  }

  // 企业所属成员列表
  enterpriseMembersCount: number = 0;
  enterpriseMembersList: EnterpriseInfoType[] = [];
  saveEnterpriseMembersData(count: number, data: EnterpriseInfoType[]) {
    this.enterpriseMembersCount = count;
    this.enterpriseMembersList = data;
  }

  memberMergedList: MemberMergedListType[] = []; // 将成员信息和数据信息合并为一个新的数组,最终用来展示的成员列表
  memberMergedListByFans: MemberMergedListType[] = []; // 按粉丝数从大到小处理展示成员列表
  memberMergedListByPlays: MemberMergedListType[] = []; // 按播放数从大到小处理展示成员列表
  memberMergedListByAlphabet: MemberMergedListType[] = []; // 按字母顺序处理展示成员列表
  // 组合成员数据信息和成员基本信息
  connectMemberInfo() {
    const mergedData: any[] = this.enterpriseMembersList.map((userObj) => {
      const userData = this.membersDataList.find((data) => data.cid === userObj.member.uid);
      return { ...userObj, ...userData };
    });

    this.memberMergedList = [...mergedData];
    this.memberMergedListByFans = [...mergedData].sort(
      (a, b) => parseInt(b.fans || '0') - parseInt(a.fans || '0')
    );
    this.memberMergedListByPlays = [...mergedData].sort(
      (a, b) => parseInt(b.plays || '0') - parseInt(a.plays || '0')
    );
    // 不区分大小写进行比较
    this.memberMergedListByAlphabet = [...mergedData].sort((a, b) =>
      (a?.user?.nickname.toUpperCase() || '') > (b?.user?.nickname.toUpperCase() || '') ? 1 : -1
    );
  }

  // 获取企业成员邀请记录列表
  async getMembersRecordList(inStatus?: MemberInviteStatusType[]) {
    const params: IGetInvitationRecordsParams = {
      limit: 999,
      offset: 0,
      inStatus,
    };
    const res = await memberServices?.getInvitationRecords(params);
    this.filterMembersRecords(res?.list);
    return Promise.resolve(res);
  }

  // 根据uid去重，每个uid仅保留一条最新的数据
  filterMembersRecords(records: InvitationRecordType[]) {
    // recordList 根据UID过滤重复数据 每个只保留最新的一条
    const filteredList: InvitationRecordType[] = records?.reduce((acc: any, curr: any) => {
      const existingIndex = acc.findIndex(
        (item: any) => item?.invitation?.uid === curr?.invitation?.uid
      );
      if (existingIndex === -1) {
        acc.push(curr);
      } else {
        const existingRecord = acc[existingIndex];
        const currentRecordCreatedTime = new Date(curr.invitation.createdTime);
        const existingRecordCreatedTime = new Date(existingRecord.invitation.createdTime);
        if (currentRecordCreatedTime > existingRecordCreatedTime) {
          acc[existingIndex] = curr;
        }
      }
      return acc;
    }, []);

    let newList: InvitationRecordType[] = filteredList.slice();

    if (this.enterpriseMembersList?.length > 0) {
      // 根据invitation.uid和member.uid，将两个数组合并为一个数组
      filteredList.forEach((filterItem) => {
        const filterMember = this.enterpriseMembersList.find(
          (memberItem) => filterItem?.invitation?.uid === memberItem?.member?.uid
        );

        if (filterMember) {
          const index = newList.findIndex(
            (item) => item?.invitation?.uid === filterItem?.invitation?.uid
          );
          newList.splice(index, 1);
        }
      });
    }
    this.saveMembersRecordsList(newList);
  }

  membersRecordsList: InvitationRecordType[] = []; // 企业成员邀请记录列表
  saveMembersRecordsList(list: InvitationRecordType[]) {
    this.membersRecordsList = list;
  }

  // 发送邀请/取消邀请成员
  async sendMemberInvitation(params: ISendMemberInvitationParams) {
    const res = await memberServices?.sendMemberInvitation(params);
    return Promise.resolve(res);
  }

  currentMemberUid: number = 0;
  currentMemberNickname: string = '-';
  currentMemberInfo: MemberMergedListType = {} as MemberMergedListType;
  // 记录当前管理的成员
  saveCurrentMember(uid: number) {
    const info = this.memberMergedList.find((item) => item.user?.uid === uid);
    if (info) {
      this.currentMemberInfo = info;
      this.currentMemberNickname = info.user?.nickname || '-';
      this.currentMemberUid = info.user?.uid || 0;
    }
  }

  //  清除当前管理的成员
  clearCurrentMember() {
    this.currentMemberUid = 0;
    this.currentMemberNickname = '-';
    this.currentMemberInfo = {} as MemberMergedListType;
    this.clearContentList();
    this.clearPlatform();
    this.clearPass();
    this.clearOpenPlatformData();
  }

  clearStore() {
    this.membersDataList = [];
    this.enterpriseMembersCount = 0;
    this.enterpriseMembersList = [];
    this.memberMergedList = [];
    this.membersRecordsList = [];
    this.enterpriseTotalData = {
      platforms: '0',
      fans: '0',
      plays: '0',
      likes: '0',
      works: '0',
      comments: '0',
    };
    this.clearCurrentMember();
    this.clearContentList();
    this.clearPlatform();
    this.clearPass();
    this.clearOpenPlatformData();
  }

  /*
   * 成员内容管理
   */

  async queryPublishCount(type: string, pidList?: number[]) {
    const res = await memberServices.queryPublishCount(this.currentMemberUid, type, pidList);
    return Promise.resolve(res.list);
  }

  async publishVideo(params: PublishVideoType) {
    const res = await memberServices.publishVideo(params);
    return Promise.resolve(res);
  }

  clearContentList() {
    this.contentList = [];
    this.totalCount = 0;
    this.hasMore = true;
  }

  async getContentList(cursor: number) {
    const res = await memberServices.getContentList(this.currentMemberUid, cursor, this.pageSize);
    if (cursor === 0) {
      this.clearContentList();
    }
    this.saveContentList(res.list, res.total);
    return Promise.resolve(res);
  }

  pageSize = 100;
  hasMore = true;
  totalCount = 0;
  contentList: ContentListType[] = [];
  saveContentList(list: ContentListType[], total: number) {
    // 合并当前数据和新请求的数据,并筛选出重复数据
    const newList = this.contentList.concat(
      list.filter((itemB) => !this.contentList.some((itemA) => itemA.id === itemB.id))
    );
    this.contentList = newList;
    this.totalCount = total;
    this.hasMore = newList.length < total;
    this.sortContentList();
    // 晒选出未填充视频数据的item
    const unFillDataList = newList.filter((item) => !item.sum).map((item) => item.id);
    if (unFillDataList.length > 0) {
      this.getContentData(unFillDataList);
    }
  }

  saveCoverList(list: DownloadBatchFiles[]) {
    const newList = this.contentList.map((itemA) => {
      const itemB = list.find((itemB) => itemB.id === itemA.coverId);

      if (itemB) {
        return { ...itemA, coverUrl: itemB.url };
      } else {
        return itemA;
      }
    });
    this.contentList = newList;
  }

  async getContentData(vids: number[]) {
    const res = await memberServices.getContentData(this.currentMemberUid, vids);
    this.saveContentData(res.videoMetrics);
    return Promise.resolve(res);
  }

  saveContentData(dataMap: {
    [key: number]: { sum: ContentDataType; platforms: ContentDataType[] };
  }) {
    const newList = this.contentList.map((item) => {
      if (dataMap[item.id]) {
        return { ...item, ...dataMap[item.id] };
      } else {
        return item;
      }
    });
    this.contentList = newList;
  }

  // 排序视频列表
  sortContentList(sort = 'default') {
    const array = this.contentList.sort((a, b) => {
      if (sort === 'default') {
        return dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
          ? 1
          : dayjs(a.createdAt).isSame(dayjs(b.createdAt))
          ? 0
          : -1;
      } else if (sort === 'plays') {
        return Number(b.sum?.views || '0') - Number(a.sum?.views || '0');
      } else {
        return Number(b.sum?.likes || '0') - Number(a.sum?.likes || '0');
      }
    });
    this.contentList = array;
  }

  // 获取视频发布的平台列表
  async getContentPlatformList(originalId: number, cursor: number) {
    const res = await memberServices.getContentPlatformList(
      this.currentMemberUid,
      originalId,
      cursor
    );
    return Promise.resolve(res);
  }

  // 转发视频到其他平台 (一键发布)
  async republishVideo(params: RepublishVideoType) {
    const res = await memberServices.republishVideo(params);
    return Promise.resolve(res);
  }

  // 获取每个平台的视频详情信息
  async getPlatformVideoInfoList(id: number, cursor: number) {
    const res = await memberServices.getPlatformVideoInfoList(this.currentMemberUid, id, cursor);
    return Promise.resolve(res);
  }

  // 获取该平台所有的视频
  async getVideosOfPlatform(platformId: number, cursor: number, size: number) {
    const res = await memberServices.getVideosOfPlatform(
      this.currentMemberUid,
      platformId,
      cursor,
      size
    );
    return Promise.resolve(res);
  }

  // 删除某个视频
  async deletePlatformVideo(id: number) {
    const res = await memberServices.deletePlatformVideo(id, this.currentMemberUid);
    return Promise.resolve(res);
  }

  /*
   * Global Pass
   */

  // 获取Global Pass信息
  globalPass: GlobalPassType = {
    id: 0,
    copyUrl: '',
    copyMoreUrl: '',
    uid: 0,
    status: 'CREATE',
  };
  isPassGlobal: boolean = false;
  isGlobalApply: boolean = false; // 用户是否已经申请Global Pass认证
  async getGlobalPass() {
    if (!this.currentMemberUid) return Promise.reject('no found member uid');
    const res = await memberServices.getGlobalPass(this.currentMemberUid);
    this.saveGlobalPass(res);
    return Promise.resolve(res);
  }

  saveGlobalPass(pass: GlobalPassType) {
    if (pass.id) {
      this.globalPass = pass;
      this.isPassGlobal = !!pass.id;
      this.isGlobalApply = pass.status !== 'CREATE';
    }
  }

  // 提交Global Pass
  async submitGlobalPass(params: GlobalPassType) {
    const res = await memberServices.submitGlobalPass(params);
    return Promise.resolve(res);
  }

  // 获取Creator Pass信息
  creatorPass: CreatorPassType = {
    id: 0,
    homepageCopyUrl: '',
    homepageMoreCopyUrl: '',
    platformId: 99,
    uid: 0,
    status: 'CREATE',
  };
  isPassCreator: boolean = false;
  async getCreatorPass() {
    if (!this.currentMemberUid) return Promise.reject('no found member uid');
    const res = await memberServices.getCreatorPass(this.currentMemberUid);
    this.saveCreatorPass(res.foreignPlatform);
    return Promise.resolve(res);
  }

  saveCreatorPass(pass: CreatorPassType) {
    if (pass.id) {
      this.creatorPass = pass;
      this.isPassCreator = pass.status === 'SUCCEED';
    }
  }

  // 提交Creator Pass
  async submitCreatorPass(params: CreatorPassType) {
    const res = await memberServices.submitCreatorPass(params);
    return Promise.resolve(res);
  }

  //创建认证历史
  async createAuthHistory(params: ICreateAuthHistoryParams) {
    const res = await memberServices.createAuthHistory(params);
    return Promise.resolve(res);
  }

  // 创建海外平台
  async createOverseaPlatform(params: OverseaPlatformParams) {
    const res = await memberServices.createOverseaPlatform(params);
    return Promise.resolve(res);
  }

  clearPass() {
    this.globalPass = {
      id: 0,
      copyUrl: '',
      copyMoreUrl: '',
      uid: 0,
      status: 'CREATE',
    };
    this.creatorPass = {
      id: 0,
      homepageCopyUrl: '',
      homepageMoreCopyUrl: '',
      platformId: 99,
      uid: 0,
      status: 'CREATE',
    };
    this.isPassGlobal = false;
    this.isGlobalApply = false;
    this.isPassCreator = false;
  }

  /*
   * 成员平台管理
   */

  // 获取平台增量数据
  async getPlatformIncreaseData(params: PlatformIncreaseParams) {
    const res = await memberServices.getPlatformIncreaseData(params);
    return Promise.resolve(res);
  }

  // 查询单个成员运营中的平台
  async getMemberPlatforms() {
    const res = await memberServices?.getMemberPlatforms(this.currentMemberUid);
    this.saveMemberPlatforms(res.list);
    return Promise.resolve(res);
  }

  // 是否有已经开通完成的平台
  isOpenPlatform: boolean = false;
  operatePlatforms: OperatePlatformType[] = []; // 开通的平台列表 (包含 申请中、申请失败、开通完成)
  tempOperatePlatforms: OperatePlatformType[] = []; // 临时存放开通平台数据
  operatePlatformsDoneList: OperatePlatformType[] = []; // 开通已完成的平台
  saveMemberPlatforms(list: OperatePlatformType[]) {
    this.tempOperatePlatforms = list;
    this.operatePlatformsDoneList = list.filter((plat) => plat.openStatus !== 'OS_DOING');

    let opened = false;
    if (list.length > 0) {
      list.forEach((item) => {
        if (item.openStatus !== 'OS_DOING') {
          opened = true;
        }
      });

      this.isOpenPlatform = opened;
    }
    // 请求平台数据
    this.getPlatformData().catch(() => {});
  }

  platformData: PlatformDataType[] = [];
  platformTotal: PlatformDataType = {};
  platformTotalIncrease: PlatformDataType = {};
  // 获取单个成员的平台数据
  async getPlatformData() {
    const res = await memberServices.getPlatformData(this.currentMemberUid);
    this.savePlatformData(res.byPlatform, res.incr, res.sum);
    return Promise.resolve(res);
  }

  // 保存平台数据
  savePlatformData(list: PlatformDataType[], increase: PlatformDataType, total: PlatformDataType) {
    this.platformData = list;
    this.platformTotal = total;
    this.platformTotalIncrease = increase;
    this.handlePlatformInfo();
  }

  // 合并平台信息和平台数据
  handlePlatformInfo() {
    const mergedArray = this.tempOperatePlatforms.map((platA) => {
      const matchingItem = this.platformData.find((platB) => platB.platformId === platA.platformId);

      if (matchingItem) {
        return {
          ...platA,
          sum: toJS(matchingItem),
        };
      }
      return toJS(platA);
    });
    this.operatePlatforms = mergedArray;
    this.tempOperatePlatforms = mergedArray;
    this.operatePlatformsDoneList = mergedArray.filter((item) => item?.openStatus !== 'OS_DOING');
  }

  sortMemberPlatforms(list: OperatePlatformType[], type = 'default') {
    const statusOrder = {
      OS_DONE_BOUND: 0,
      OS_DONE_BINDING: 1,
      OS_DONE_UNBIND: 2,
      OS_DOING: 3,
      OS_UNKNOWN: 10,
    };

    // 排序
    const sorted = list.sort((a, b) => {
      if (a.isFroze !== b.isFroze) {
        return Number(a.isFroze) - Number(b.isFroze);
      }
      if (a.openStatus !== b.openStatus) {
        return statusOrder[a.openStatus] - statusOrder[b.openStatus];
      }
      // 当 status 为 'DONE' 时，按照 play / like 从大到小排序
      if (a.openStatus !== 'OS_DOING') {
        if (type === 'likes') {
          return (b.sum?.likes || 0) - (a.sum?.likes || 0);
        } else if (type === 'plays') {
          return (b.sum?.plays || 0) - (a.sum?.plays || 0);
        } else {
          return dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
            ? -1
            : dayjs(a.createdAt).isSame(dayjs(b.createdAt))
            ? 0
            : 1;
        }
      }

      return 0;
    });

    this.operatePlatforms = sorted;
    this.tempOperatePlatforms = sorted;
    this.operatePlatformsDoneList = sorted.filter((item) => item?.openStatus !== 'OS_DOING');
  }

  clearPlatform() {
    this.isOpenPlatform = false;
    this.operatePlatforms = [];
    this.tempOperatePlatforms = [];
    this.platformData = [];
    this.platformTotal = {};
    this.platformTotalIncrease = {};
    this.operatePlatformsDoneList = [];
  }

  // 收到新的开通平台消息
  isNewPlatformMessage = false;
  setIsNewPlatformMessage(value: boolean) {
    this.isNewPlatformMessage = value;
  }

  // 创建运营平台
  async createOperatePlatform(params: OperatePlatformParams) {
    const res = await memberServices.createOperatePlatform(params);
    return Promise.resolve(res);
  }

  //保存channel id
  channelId: string = '';
  platformUid: number = 99;
  saveChannelUrl(channelId: string, platformUid: number) {
    this.channelId = channelId;
    this.platformUid = platformUid;
  }

  openingPlatformId: number = 0;
  saveOpeningPlatformId(platformId: number) {
    this.openingPlatformId = platformId;
  }

  //保存global pass
  normalPhotoUrl: string = '';
  holdPhotoUrl: string = '';
  saveGlobalPassPhoto(normalPhotoUrl: string, holdPhotoUrl: string) {
    this.normalPhotoUrl = normalPhotoUrl;
    this.holdPhotoUrl = holdPhotoUrl;
  }

  //清除开通平台数据
  clearOpenPlatformData() {
    this.platformUid = 99;
    this.openingPlatformId = 0;
    this.channelId = '';
    this.normalPhotoUrl = '';
    this.holdPhotoUrl = '';
  }

  // 进入到提交验证码页面,准备输入验证码
  async readyToGetCode(params: PlatformCodeParams) {
    const res = await memberServices.readyToGetCode(params);
    return Promise.resolve(res);
  }

  // 发送/提交验证码 (code为-1时为重新发送, 传code时为提交)
  async submitPlatformCode(params: PlatformCodeParams) {
    const res = await memberServices.submitPlatformCode(params);
    return Promise.resolve(res);
  }

  // 获取用户输入的验证码,用来判断用户有没有输入过
  async getMemberPlatformCode(pid: number, sessionType: string) {
    if (!this.currentMemberUid) return Promise.reject('no found member uid');
    const res = await memberServices.getMemberPlatformCode(this.currentMemberUid, pid, sessionType);
    return Promise.resolve(res);
  }
}
